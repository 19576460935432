import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink
} from "react-router-dom";
import logo from './logo.svg';
import './App.css';
import { makeStyles  } from '@material-ui/core/styles';
// import { AccessAlarm, ThreeDRotation } from '@material-ui/icons';
import { Facebook, LinkedIn, GitHub, Menu } from '@material-ui/icons';
import Button from '@material-ui/core/Button';
import { LanguageChip, LanguagesComp } from './MyChips';
import { InfoCard } from './MyCards';
import { Icons8Icon, MeWe } from './MyIcons';
 
const useStyles = makeStyles((theme) => ({
  menu: {
    position: "fixed",
    left: 0,
    top: 0,
    // webkitTransform: translateX(0),
    transform: "translateX(0)",
    height: "100vh",
    width: "300px",
    background: "#191d2b",
    borderRight: "1px solid #2e344e",
    zIndex: "10",
    webkitTransition: "all .4s ease-out",
    transition: "all .4s ease-out",
  },
  briefIntro: {
    textAlign: "center",
    width: "40em",
  },
  socialButtonGroup: {
    marginTop: "2em",
    "& li": {
      listStyle: "none",
      display: "inline-block",
      margin: "0 8px",
      
    "& .MuiSvgIcon-root": { fontSize: "3.5rem" }
    },
  },
  commonContainer: {
    margin: "60px auto",
    width: '100%',
    paddingRight: "15px",
    paddingLeft: "15px",
    [theme.breakpoints.down('sm')]: {
      maxWidth: "540px",
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: "720px",
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: "960px",
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: "960px",
    },
  }, 
  content: {
    backgroundColor: "#282c34",
    minHeight: "100vh",
    display: "flex",
    fontSize: "calc(10px + 2vmin)",
    color: "white",
    flexDirection: "column",
    [theme.breakpoints.down('md')]: {
      flexDirection: "unset",
    },
  },
  introContent: {
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    marginBottom: "30px",
    position: "relative",
    zIndex: "1",
    "& h2": {
      paddingBottom: "15px",
    },
    "& h2:before": {
      content: `""`,
      position: "absolute",
      left: "0",
      top: "auto",
      bottom: "0",
      height: "5px",
      borderRadius: "100px",
      width: `100px`,
      background: `rgba(3,127,255,.3)`,
    },
  }, 
  about: {
    "& ul" : {
      paddingLeft: 0,
    }
  },
  data:{
    "& li" : {
      listStyle: "none",
      display: "flex",
      "& b" : {
        minWidth: "150px",
        display: "inline-block",
        position: "relative",
        marginRight: "10px",
        [theme.breakpoints.down('sm')]: {
          minWidth: "110px",
        },
        [theme.breakpoints.up('sm')]: {
          minWidth: "150px",
        },
        [theme.breakpoints.up('md')]: {
          minWidth: "250px",
        },
      },
      "& b:after": {
        content: `":"`,
        position: `absolute`,
        top: 0,
        left: `auto`,
        right: 0,
      }
    }
  },
  feature: {
    fontWeight: "bold",
    color: "#8281ff",
    display: "inline-block",
  },
  sector: {
    marginBottom: "40px",
  },
  progress: {
    "& h6": {
      marginBottom: 0,
    },
    "& div": {
      display: "flex",
      alignItems: "center",
    },
  },
  // Resume
  workingDiv: {    
    borderLeft: "3px solid #2e344e",
    minHeight: "100px",
  },
  workingGrid: {
    display: "flex",
    marginBottom: "20px",
    "WebkitTransition": "opacity 1s ease-in-out",
    "MozTransition": "opacity 1s ease-in-out",
    "MsTransition": "opacity 1s ease-in-out",
    "OTransition": "opacity 1s ease-in-out",
    "transition": "opacity 1s ease-in-out",
    "& h6,h5,p" : {
      margin: "5px 0",
    }, 
    [theme.breakpoints.down('md')]: {
      flexWrap: "wrap",
    },
  },
  highlighted_chip: {
    border: "2px solid #ffc107",
  },
  workingYear: {
    "alignSelf": "flex-start",
    "flex": "0 0 220px",
    "maxWidth": "220px",
    "paddingLeft": "20px",
    "position": "relative",
    "&::before" : {
      "top": "0px",
      "left": "-20px",
      "width": "30px",
      "border": "3px solid #2e344e",
      "height": "30px",
      "content": "\"\"",
      "position": "absolute",
      // "background": "url(https://media-exp1.licdn.com/dms/image/C510BAQER80uZGSbnGg/company-logo_100_100/0?e=1599696000&v=beta&t=lTwYNJl-vVpHRMtD9Hn2vXVKTYkVm-RycDw5foyUIP4)",
      "borderRadius": "100px",
      "backgroundSize": "contain",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center center",
    },
  },
  workingDetail: {
    "position": "relative",
    "paddingLeft": "50px", 
    [theme.breakpoints.down('md')]: {
      "paddingLeft": "20px", 
    },
  },
  highlight: {
    fontWeight: "bold",
  },
  filter: {

  }, 
  dim: {
    opacity: "50%",
  },
  workingDesc: {
    "&>ul" : {
      "&>li": {
        marginTop: "10px",
        marginBottom: "10px",
      }
    }
  },
  //all  
  note : {
    margin: "0 10px",
    fontSize: "0.5em",
    opacity: "0.7",
  }
})); 

function App() {
  const [menuShow, setMenuShow] = React.useState(-1);
  return (
    <div className="App">
      <Router>
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route path="/credit">
            <Credit /> 
          </Route>
          <Route path="/education">
            <Education />
          </Route>
          <Route path="/resume">
            <Resume />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
        <div className={`${"mi-header"} ${ menuShow > 0 && "menu-show" || "" }`} >
          <button class="mi-header-toggler"><Menu onClick={ () => setMenuShow(menuShow * -1)} /></button>
          <div className="menu-inner">
            <div></div>
            <ul className="menu-list">
              <li>
                <NavLink to="/" exact onClick={ () => setMenuShow(-1) } >Home</NavLink>
              </li>
              <li>
                <NavLink to="/about" exact onClick={ () => setMenuShow(-1) } >About</NavLink>
              </li>
              <li>
                <NavLink to="/resume" exact onClick={ () => setMenuShow(-1) } >Experience</NavLink>
              </li>
              <li>
                <NavLink to="/education" exact onClick={ () => setMenuShow(-1) } >Education</NavLink>
              </li>
              <li>
                <NavLink to="/credit" exact onClick={ () => setMenuShow(-1) } >Credit</NavLink>
              </li>
            </ul>
            <div></div>
          </div>
        </div>
      </Router>
    </div>
  );
}

function Home(){
  const classes = useStyles();
  return (
    <div className={`${classes.content} ${classes.introContent}`}>
      <div className={classes.briefIntro}>
        <h1>
          Hi, my name is <span className={classes.feature}>Tommy Lin</span>
        </h1>
        <p>
          Thank you for taking time to visit my personal portfolio website
        </p>
        <ul className={classes.socialButtonGroup}>
              <li><a rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/tommy.lin.5074"><Facebook /></a></li>
              <li><a rel="noopener noreferrer" target="_blank" href="https://github.com/linkwodin"><GitHub /></a></li>
              <li><a rel="noopener noreferrer" target="_blank" href="https://www.linkedin.com/in/tommy-lin-26856913b/"><LinkedIn /></a></li>
              <li><a rel="noopener noreferrer" target="_blank" href="https://mewe.com/i/tommylin1"><MeWe /></a></li>
        </ul>
      </div>
    </div>
  );
}

function About() {
  const classes = useStyles();
  return (
    <div className={classes.content}>
      <div className={classes.commonContainer}>
        <div className={classes.sector}>
          <div className={classes.title}>
            <h2>About Me</h2>
          </div>
          <div>
            <div className={classes.about}>
              <h3>I am <span className={classes.feature}>Tommy Lin</span></h3>
              <p>Experienced Developer with a demonstrated history of working in the retail industry. Skilled in Java, GoLang, Hybris, JavaScript, MySQL, and HTML5. Strong engineering professional with a Higher Diploma focused in Computer Software Engineering from Hong Kong Institute of Vocational Education.</p>
              <ul className={classes.data}>
                <li><b>Nationality</b> Hong Kong</li>
                <li><b>Languages</b> Cantonese, English</li>
                <li><b>Email</b> linkwodin@gmail.com</li>
              </ul>
              <a href={process.env.PUBLIC_URL + '/cv.pdf'} download>
                <Button color="primary">
                    Download CV
                </Button>
              </a>
            </div>
          </div>
        </div>
        <div className={classes.sector}>
          <div className={classes.title}>
              <h2>Social Media</h2>
          </div>
          <div>
            <ul className={classes.socialButtonGroup}>
              <li><a rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/tommy.lin.5074"><Facebook /></a></li>
              <li><a rel="noopener noreferrer" target="_blank" href="https://github.com/linkwodin"><GitHub /></a></li>
              <li><a rel="noopener noreferrer" target="_blank" href="https://www.linkedin.com/in/tommy-lin-26856913b/"><LinkedIn /></a></li>
              <li><a rel="noopener noreferrer" target="_blank" href="https://mewe.com/i/tommylin1"><MeWe /></a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}



function Resume() {
  const classes = useStyles();

  const [filter, setFilter] = React.useState(0);

  return (
    <div className={classes.content}>
      <div className={classes.commonContainer}>
        <div className={classes.sector}>
          <div className={classes.title}>
            <h2>Skills Set </h2> 
          </div>
          <div>
            { LanguagesComp != undefined && Object.keys(LanguagesComp).map(language=>(
              <LanguageChip language={language} className={ filter != "" && filter == language && classes.highlighted_chip || "" }
              clickable
              onClick={() => { 
                if (filter == language) {
                  setFilter("") 
                } else { 
                  setFilter(language);
                }
                }} />
            ))}
          </div>
          <span className={classes.note}>* click skill to filter</span>
        </div>
        <div className={classes.sector}>
          <div className={classes.title}>
              <h2>Working Experience</h2>
          </div>
          <div className={classes.workingDiv}>
            <WorkingExperienceEntity year="Jul 2020 - Present" title="Senior Java Software Developer" company="Comestri" 
                languages={["Java", "Spring", "HTML5", "CSS3", "JavaScript"]} companyImgClass="workingComestri" filter={filter}>
                  <ul>
                    <li>Web service for arrange order, stock and logistic for clients' e-commerce website and physical store</li>
                    <li>Java Spring Web Application</li>
                    <li>Implement refund at different payment gateways</li>
                    <li>Implement shipping couriers</li>
                    <li>Develop automated testing tools for the system</li>
                    <li>Support clients' request and issues</li>
                  </ul>
            </WorkingExperienceEntity>
            <WorkingExperienceEntity year="Apr 2020 - Jul 2020" title="Senior Analyst Programmer" company="China Construction Bank (Asia) Corporation Limited" 
                languages={["Java", "Spring", "HTML5", "CSS3", "JavaScript"]} companyImgClass="workingCCBA" filter={filter}>
                  <ul>
                    <li>HKSAR The 2020 - 2021 Budget $10,000 cash payout</li>
                    <li>Middleware to gather application from different channel and submit to the Government OGCIO</li>
                    <li>Java Spring Boot Web Application</li>
                    <li>Two-Month Contract</li>
                  </ul>
            </WorkingExperienceEntity>
            <WorkingExperienceEntity year="Jul 2018 - Apr 2020" title="Senior Software Engineer" company="Sandbox VR" 
                languages={["GoLang", "Java", "Spring", "Ansible", "HTML5", "CSS3", "JavaScript", "React", "JQuery"]} companyImgClass="workingSandboxVR" filter={filter}>
                  <ul>
                    <li>
                      DevOps
                      <ul>
                        <li>Java Spring Boot Web application for different teams to upload their program, software, game and config </li>
                        <li>Ansible to install / update the programs, software... to the servers and gaming machines located in store at different country</li>
                      </ul>
                    </li>
                    <li>
                      Development
                      <ul>
                        <li>GoLang micro web service for store staff to control the game sessions.</li>
                        <li>Gather user story to design the program flow and structure</li>
                        <li>Upload the Game result to different endpoints such as GCP and Cloudinary</li>
                      </ul>
                    </li>
                  </ul>
            </WorkingExperienceEntity>
            <WorkingExperienceEntity year="Jul 2016 - Jun 2018" title="Hybris Developer" company="A.S. Watson Group" 
                languages={["Java", "Hybris", "Spring", "HTML5", "CSS3", "JQuery", "JavaScript", "Elk Stack"]} companyImgClass="workingASWatson" filter={filter}>
                  <ul>
                    <li>Develop the new e-commerce web for Fortress by SAP Hybris, Java and Spring</li>
                    <li>Extend the login process of Hybris to support the existing CRM system of AS Watson</li>
                    <li>The email sending system (Email Template, initial Business Process) for registration, order confirmation</li>
                    <li>Configure the Solr product searching results</li>
                    <li>Confirm the localization the wordings with Users</li>
                    <li>Hot Folder Implementation (Date import)</li>
                    <li>Handling bugs fix and Change Requests</li>
                    <li>Conduct Stress tests for the web site by jMeter and Selenium</li>
                    <li>Implement elk stack to analyse the production performance and customer searching behaviour</li>
                  </ul>
            </WorkingExperienceEntity>
            <WorkingExperienceEntity year="Jul 2014 - Apr 2016" title="Software Engineer" company="Megasoft Limited" 
                languages={["Java", "HTML5", "CSS3", "JQuery", "C#", "Android", "JavaScript"]} companyImgClass="workingMegasoft" filter={filter}>
                  <ul>
                    <li>Java Servlet and jsp web page for a POS backend system, Device Monitoring System, User Managements based on the internal RFID devices. For example, add an item to the cart based on the RFID reader results</li>
                    <li>RFID POS Application development for Android</li>
                    <li>C# windows for controlling RFID printer form maintenance</li>
                    <li>Assign tasks to junior engineers</li>
                  </ul>
            </WorkingExperienceEntity>
          </div>
        </div>
      </div>
    </div>
  );
}

{/* <div className={classes.workingGrid}>
<div className={classes.workingYear}>
  <h6 class="mi-resume-year">2020 - Present</h6>
</div>
<div className={classes.workingDetail}>
  <h5>senior analyst programmer</h5>
  <h6 class="mi-resume-company">China Construction Bank (Asia) Corporation Limited</h6>
  <p>develop a java spring boot application as a middleware for the bank to submit "10k application" form to the government</p>
  <div>
    <SpringChip />
    <JavaChip />
    <HTML5Chip />
  </div>
</div>
</div> */}

function WorkingExperienceEntity(props){
  const classes = useStyles();
  return (
    <div className={`${classes.workingGrid} working ${props.languages != undefined && props.filter != "" && !props.languages.includes(props.filter) && classes.dim }`} >
      <div className={`${classes.workingYear} ${props.companyImgClass}`}>
        <h6 class="mi-resume-year">{props.year}</h6>
      </div>
      <div className={classes.workingDetail}>
        <h5 className={classes.feature}>{props.title}</h5>
        <h6 class="mi-resume-company">{props.company}</h6>
        <div className={classes.workingDesc}>{props.children}</div>
        <div>
          { props.languages != undefined && props.languages.map(language=>(
            <LanguageChip language={language} />
          ))}
        </div>
      </div>
    </div>
  );
}

function Education() {
  const classes = useStyles();
  return (
    <div className={classes.content}>
      <div className={classes.commonContainer}>
        <div className={classes.sector}>
          <div className={classes.title}>
            <h2>Educational Qualifications</h2>
          </div>
          <div className={classes.workingDiv}>
            <WorkingExperienceEntity year="Sep 2012 - Dec 2014" title="Higher Diploma in Computer Software Engineering" company="Hong Kong Institute of Vocational Education" companyImgClass="eduIve">
            </WorkingExperienceEntity>
          </div>
        </div>
      </div>
    </div>
  );
}

function Credit() {
  const classes = useStyles();
  return (
    <div className={`${classes.content} ${classes.introContent}`}>
      <div className={classes.commonContainer}>
        <div className={classes.sector}>
          <div className={classes.title}>
            <h2>Credit</h2>
          </div>
          <div>
            <InfoCard icon={<img src={logo} className="App-logo" alt="logo" style={{width: "48px", height: "48px"}}/>} title="React" info="Written by" href="https://reactjs.org/" />
            <InfoCard icon={<Icons8Icon />} title="icons8.com" info="Icons from" href="https://icons8.com/"/>
          </div>
        </div>
      </div>
    </div>
  );
}


export default App;
