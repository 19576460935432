import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import NavigateNext from '@material-ui/icons/NavigateNext';
import { Row, Item } from '@mui-treasury/components/flex';
import { Info, InfoTitle, InfoSubtitle } from '@mui-treasury/components/info';
import { useTutorInfoStyles } from '@mui-treasury/styles/info/tutor';
import { useSizedIconButtonStyles } from '@mui-treasury/styles/iconButton/sized';

const useStyles = makeStyles(() => ({
  item: {
      margin: "20px", 
  }, 
  icon: {
    width: "48px",
    height: "48px",
  },
  action: {
    backgroundColor: '#fff',
    boxShadow: '0 1px 4px 0 rgba(0,0,0,0.12)',
    '&:hover': {
      backgroundColor: '#fff',
      color: '#000',
    },
  },
}));

export const InfoCard = function(props) {
  const styles = useStyles();
  const iconBtnStyles = useSizedIconButtonStyles({ padding: 6 });
  return (
    <Row className={styles.item} p={1.5} gap={2} bgcolor={'#f5f5f5'} wrap grow  borderRadius={16}>
      <Item className={styles.icon}>
          {props.icon}
      </Item>
      <Info grow position={'middle'} useStyles={useTutorInfoStyles}>
        <InfoTitle>{props.title}</InfoTitle>
        <InfoSubtitle>{props.info}</InfoSubtitle>
      </Info>
      { 
        props.href != undefined && 
        <Item ml={1} position={'middle'} style={{margin: 0}}>
            <a href={props.href}  target="_blank" rel="noopener noreferrer">
                <IconButton className={styles.action} classes={iconBtnStyles}>
                    <NavigateNext />
                </IconButton>
            </a>
        </Item>
      }
    </Row>
  );
}