import React from 'react';

import Chip from '@material-ui/core/Chip';
import { JavaIcon, SpringIcon, HybrisIcon, GoLangIcon, JavaScriptIcon, HTML5Icon, CSS3Icon, 
    JqueryIcon, AnsibleIcon, VisualStudioIcon, AndroidIcon, ReactIcon, ElkIcon } from './MyIcons';
import { styled  } from '@material-ui/core/styles';


const TommyChip = styled(Chip)({
    margin: "10px 10px",
});

export const LanguagesComp = {
    "Java": JavaIcon,
    "Spring": SpringIcon,
    "Hybris": HybrisIcon,
    "GoLang": GoLangIcon,
    "JavaScript": JavaScriptIcon,
    "HTML5": HTML5Icon,
    "CSS3": CSS3Icon,
    "JQuery": JqueryIcon,
    "Ansible": AnsibleIcon,
    "React": ReactIcon,
    "C#": VisualStudioIcon,
    "Android": AndroidIcon,
    "Elk Stack": ElkIcon,
}


export function LanguageChip(props) {
    let Icon = LanguagesComp[props.language]
    return (
        <TommyChip
            icon={<Icon />}
            label={props.language}
            color="primary"
            {...props}
        />
    );
}
